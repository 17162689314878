enum StepsEnum {
  CATEGORY,
  SERVICE,
  DATE_TIME,
  TYPE,
  LOCATION,
  CREATE_PROFILE,
  PAYMENT
}

export const StepLabels = {
  [StepsEnum.CATEGORY]: 'Select a therapy',
  [StepsEnum.SERVICE]: 'Service',
  [StepsEnum.DATE_TIME]: 'Select date & time',
  [StepsEnum.TYPE]: 'Appointment type',
  [StepsEnum.LOCATION]: 'Location',
  [StepsEnum.CREATE_PROFILE]: 'Login/Sign Up',
  [StepsEnum.PAYMENT]: 'Payment',
};

export default StepsEnum;
