import { ReactElement } from 'react';
import { Icon, IconProps } from '../icon';

const People = ({ size = 24, ...props }: IconProps): ReactElement => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <ellipse cx="10" cy="13.7493" rx="5" ry="2.08333" strokeWidth="1.2" strokeLinejoin="round" />
      <ellipse cx="10" cy="6.66602" rx="2.5" ry="2.5" strokeWidth="1.5" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.62884 10.918C4.48485 10.9386 3.42295 11.1321 2.60851 11.4579C2.17921 11.6296 1.78146 11.8529 1.47877 12.1391C1.1745 12.4269 0.916666 12.83 0.916666 13.3328C0.916666 13.8356 1.1745 14.2387 1.47877 14.5265C1.78146 14.8127 2.17921 15.0359 2.60851 15.2077C3.07812 15.3955 3.63001 15.5394 4.23247 15.6315C3.69694 15.1133 3.37577 14.5164 3.33725 13.8792C3.27793 13.8584 3.22068 13.837 3.1656 13.8149C2.84088 13.6851 2.62844 13.5492 2.50943 13.4366C2.4565 13.3866 2.43244 13.3519 2.42198 13.3328C2.43244 13.3137 2.4565 13.279 2.50943 13.229C2.62844 13.1164 2.84088 12.9805 3.1656 12.8506C3.29714 12.798 3.44105 12.7488 3.59597 12.7038C3.95756 12.0019 4.67399 11.3848 5.62884 10.918ZM2.41454 13.3503C2.41441 13.3503 2.41465 13.3489 2.41566 13.3462C2.41517 13.3489 2.41467 13.3503 2.41454 13.3503ZM2.41566 13.3194C2.41465 13.3167 2.41441 13.3153 2.41454 13.3153C2.41467 13.3153 2.41517 13.3167 2.41566 13.3194Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6628 13.8792C16.6243 14.5164 16.3031 15.1133 15.7676 15.6315C16.37 15.5394 16.9219 15.3955 17.3915 15.2077C17.8208 15.0359 18.2186 14.8127 18.5213 14.5265C18.8255 14.2387 19.0834 13.8356 19.0834 13.3328C19.0834 12.83 18.8255 12.4269 18.5213 12.1391C18.2186 11.8529 17.8208 11.6296 17.3915 11.4579C16.5771 11.1321 15.5152 10.9386 14.3712 10.918C15.326 11.3848 16.0425 12.0019 16.4041 12.7038C16.559 12.7488 16.7029 12.798 16.8344 12.8506C17.1591 12.9805 17.3716 13.1164 17.4906 13.229C17.5435 13.279 17.5676 13.3137 17.578 13.3328C17.5676 13.3519 17.5435 13.3866 17.4906 13.4366C17.3716 13.5492 17.1591 13.6851 16.8344 13.8149C16.7793 13.837 16.7221 13.8584 16.6628 13.8792ZM17.5855 13.3503C17.5854 13.3503 17.5849 13.3489 17.5844 13.3462C17.5854 13.3489 17.5856 13.3503 17.5855 13.3503ZM17.5844 13.3194C17.5849 13.3167 17.5854 13.3153 17.5855 13.3153C17.5856 13.3153 17.5854 13.3167 17.5844 13.3194Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8142 8.34711C13.6078 8.81512 13.3178 9.23797 12.963 9.59673C13.3174 9.80067 13.7284 9.91732 14.1667 9.91732C15.5014 9.91732 16.5833 8.83534 16.5833 7.50065C16.5833 6.16596 15.5014 5.08398 14.1667 5.08398C14.0638 5.08398 13.9625 5.09041 13.863 5.10288C14.049 5.56161 14.1556 6.06099 14.1659 6.58398C14.1661 6.58398 14.1664 6.58398 14.1667 6.58398C14.6729 6.58398 15.0833 6.99439 15.0833 7.50065C15.0833 8.00691 14.6729 8.41732 14.1667 8.41732C14.0418 8.41732 13.9227 8.39234 13.8142 8.34711Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.13699 5.10288C6.03753 5.09041 5.93618 5.08398 5.83333 5.08398C4.49864 5.08398 3.41667 6.16596 3.41667 7.50065C3.41667 8.83534 4.49864 9.91732 5.83333 9.91732C6.27157 9.91732 6.68256 9.80067 7.03695 9.59673C6.68224 9.23797 6.39221 8.81512 6.1858 8.34711C6.07729 8.39234 5.95823 8.41732 5.83333 8.41732C5.32707 8.41732 4.91667 8.00691 4.91667 7.50065C4.91667 6.99439 5.32707 6.58398 5.83333 6.58398C5.83361 6.58398 5.83388 6.58398 5.83415 6.58398C5.84441 6.06099 5.95103 5.56161 6.13699 5.10288Z"
      />
    </svg>
  </Icon>
);

export default People;
