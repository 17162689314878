import { ReactElement } from 'react';
import {
  Box,
  styled,
  Stepper as MuiStepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  Typography, MobileStepper, Button, useTheme,
} from '@mui/material';
import StepsEnum, { StepLabels } from '../enums/steps.enum';
import { colors } from '../../../../../../config/theme/colors';
import useIsMobile from '../../../../../../hooks/use-is-mobile.hook';
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";

const StepsContainer = styled(Box)(
  () => `
    display: flex;
    justify-content: center;
    padding: 25px 0;
  `,
);

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.primary[70],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.primary[70],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

interface Props {
  activeStep: StepsEnum;
  stepDescription?: string;
  onStepChange: (step: StepsEnum) => void;
  handleNext?: any;
  handleBack?: any;
}

const Stepper = ({ activeStep, stepDescription, onStepChange, handleNext = null, handleBack = null}: Props): ReactElement => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  return (
    <>
      {(isMobile) ?
          <MobileStepper
              variant="progress"
              steps={6}
              position="static"
              activeStep={activeStep}
              sx={{ maxWidth: 900, flexGrow: 1, margin: 'auto', width: '100%', pb: '20px', pt: 1}}
              nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
                  Next
                  {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                  ) : (
                      <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                  ) : (
                      <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
          /> :
          <>{stepDescription && <Typography variant="h3">{stepDescription}</Typography>}
            <StepsContainer>
              <MuiStepper sx={{width: isMobile ? '100%' : '95%' }} activeStep={activeStep} connector={<Connector />}>
                {Object.values(StepsEnum)
                    .filter((step) => typeof step === 'number')
                    .map((step, index) => {
                      return (
                          <Step key={index} onClick={() => onStepChange(step as StepsEnum)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                            <StepLabel
                                slotProps={{
                                  label: {
                                    style: { color: activeStep === step ? colors.primary[70] : colors.secondary[90] },
                                  },
                                }}
                            ></StepLabel>
                            {!isMobile ?  <Typography sx={{fontSize:'12px'}} variant="body1" style={{ position: 'absolute', maxWidth: '100px', textAlign: 'left', marginTop: 30 }}>
                              {StepLabels[step as StepsEnum]}
                            </Typography> : null}
                          </Step>
                      );
                    })}
              </MuiStepper>
            </StepsContainer>
          </>
      }</>
  );
};

export default Stepper;
