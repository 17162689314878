import { ReactElement } from 'react';
import { Icon, IconProps } from '../icon';

const InvisibleEye = ({ size = 24, ...props }: IconProps): ReactElement => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path d="M4 4L20 20" stroke="#6F7188" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M14 14.2361C13.4692 14.7111 12.7684 15 12 15C10.3431 15 9 13.6569 9 12C9 11.2316 9.28885 10.5308 9.76389 10"
        stroke="#6F7188"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.6078 15.6077C20.1791 15.1103 20.6902 14.6099 21.1303 14.1469C22.2899 12.9268 22.2899 11.0732 21.1303 9.8531C19.1745 7.79533 15.8155 5 12 5C11.1086 5 10.2422 5.15256 9.4127 5.41264M6.5 6.80338C5.04144 7.73444 3.79764 8.87678 2.86971 9.8531C1.7101 11.0732 1.7101 12.9268 2.86971 14.1469C4.82549 16.2047 8.18448 19 12 19C13.8681 19 15.6267 18.3299 17.1648 17.4044"
        stroke="#6F7188"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  </Icon>
);

export default InvisibleEye;
