import { ReactElement } from 'react';
import StepsEnum, {StepLabels} from '../enums/steps.enum';
import {
  Box, Button, MobileStepper,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper as MuiStepper,
  styled,
  Typography, useTheme
} from '@mui/material';
import { colors } from '../../../../../config/theme/colors';
import useIsMobile from "../../../../../hooks/use-is-mobile.hook";
import {useQuery} from "../../../../../hooks";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import stepsEnum from "../enums/steps.enum";

const StepsContainer = styled(Box)(
  () => `
    display: flex;
    justify-content: center;
    padding: 80px 0;
  `,
);

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.primary[70],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.primary[70],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

interface Props {
  activeStep: StepsEnum;
  stepDescription?: string;
  onStepChange: (step: StepsEnum) => void;
  isWideStepper?: boolean;
  handleNext?: any;
  handleBack?: any;
  authUser?: boolean;
}

const Stepper = ({activeStep, stepDescription, onStepChange, isWideStepper = false, handleNext = null, handleBack = null, authUser = false }: Props): ReactElement => {
  const isMobile = useIsMobile();
  const { params } = useQuery();
  const currentUrl = window.location.pathname;
  const theme = useTheme();

  return (
    <>
      {isMobile ?
          <MobileStepper
            variant="progress"
            steps={6}
            position="static"
            activeStep={activeStep}
            sx={{ maxWidth: 900, flexGrow: 1, margin: 'auto', width: '100%', pb: '20px', pt: 1}}
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
                Next
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          /> :
          <>{stepDescription && <Typography variant="h3">{stepDescription}</Typography>}
            <StepsContainer maxWidth={isWideStepper ? 'lg': 'md'} sx={{pb: isMobile? '20px' : '80px', pt: isMobile ? 3 : params && (params.openCartModal || currentUrl.includes('/signup-last-step')) ? 3 : 15, margin: 'auto' }}>
              <MuiStepper sx={{ width: '100%' }} activeStep={activeStep} connector={<Connector />}>
                {Object.values(StepsEnum)
                    .filter((step) => typeof step === 'number')
                    .map((step, index) => {
                      if (authUser && step === stepsEnum.CREATE_PROFILE) {
                        return;
                      } else {
                        return (
                            <Step key={index} onClick={() => onStepChange(step as StepsEnum)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                              <StepLabel
                                  slotProps={{
                                    label: {
                                      style: { color: activeStep === step ? colors.primary[70] : colors.secondary[90] },
                                    },
                                  }}
                              >
                              </StepLabel>
                              {!isMobile ?
                                  <Typography variant="body1" sx={{fontSize:'12px'}} style={{ position: 'absolute', maxWidth: '100px', textAlign: 'left', marginTop: 30 }}>
                                    {StepLabels[step as StepsEnum]}
                                  </Typography> :
                                  null
                              }
                            </Step>
                        );
                      }
                    })
                }
              </MuiStepper>
            </StepsContainer>
          </>
      }
    </>
  );
};

export default Stepper;
