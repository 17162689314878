import { ReactElement } from 'react';
import { Icon, IconProps } from '../icon';

const Donation = ({ size = 24, ...props }: IconProps): ReactElement => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <rect x="10.8333" y="2.5" width="7.5" height="7.5" rx="2" strokeWidth="1.5"/>
      <path d="M15.4167 5L13.75 5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.66669 7.5H3.33335L8.55189 9.78311C9.35342 10.1338 9.722 11.0654 9.37737 11.8695V11.8695C9.03089 12.678 8.09465 13.0525 7.2862 12.706L5.83335 12.0833" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.0021 14.3292C1.63161 14.1439 1.18111 14.2941 0.995867 14.6646C0.810625 15.0351 0.960793 15.4856 1.33128 15.6708L2.0021 14.3292ZM9.5021 11.8292L8.83128 11.4938L8.16046 12.8354L8.83128 13.1708L9.5021 11.8292ZM11.2331 13.5332L10.8977 14.2041L11.2331 13.5332ZM13.9164 14.9167H15V13.4167H13.9164V14.9167ZM15 16.75H8.55523V18.25H15V16.75ZM5.31293 15.9846L2.0021 14.3292L1.33128 15.6708L4.64211 17.3262L5.31293 15.9846ZM11.5686 12.8624L9.5021 11.8292L8.83128 13.1708L10.8977 14.2041L11.5686 12.8624ZM8.55523 16.75C7.4297 16.75 6.31963 16.4879 5.31293 15.9846L4.64211 17.3262C5.8571 17.9337 7.19684 18.25 8.55523 18.25V16.75ZM15.9167 15.8333C15.9167 16.3396 15.5063 16.75 15 16.75V18.25C16.3347 18.25 17.4167 17.168 17.4167 15.8333H15.9167ZM15 14.9167C15.5063 14.9167 15.9167 15.3271 15.9167 15.8333H17.4167C17.4167 14.4986 16.3347 13.4167 15 13.4167V14.9167ZM13.9164 13.4167C13.1014 13.4167 12.2975 13.2269 11.5686 12.8624L10.8977 14.2041C11.835 14.6727 12.8685 14.9167 13.9164 14.9167V13.4167Z"/>
    </svg>
  </Icon>
);

export default Donation;
