import { ReactElement } from 'react';
import { Icon, IconProps } from '../icon';

const Users = ({ size = 24, ...props }: IconProps): ReactElement => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <ellipse cx="8.33333" cy="14.5837" rx="5.83333" ry="2.91667" strokeWidth="1.5" strokeLinejoin="round"/>
      <circle cx="8.33333" cy="5.83333" r="3.33333" strokeWidth="1.5" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.6175 8.41292C12.3097 8.92294 11.9135 9.37354 11.45 9.74346C11.7794 9.85583 12.1326 9.9168 12.5 9.9168C14.2949 9.9168 15.75 8.46173 15.75 6.6668C15.75 4.94427 14.4099 3.53473 12.7154 3.42383C12.9969 3.93459 13.1916 4.49985 13.2799 5.09975C13.8549 5.38651 14.25 5.98052 14.25 6.6668C14.25 7.59382 13.5292 8.35249 12.6175 8.41292Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.3242 16.2463C15.6529 15.7307 15.8333 15.1697 15.8333 14.583C15.8333 14.5533 15.8329 14.5237 15.8319 14.4941C16.1844 14.3387 16.4303 14.1745 16.5807 14.0263C16.7403 13.8691 16.75 13.776 16.75 13.7497C16.75 13.7233 16.7403 13.6303 16.5807 13.473C16.4185 13.3132 16.1452 13.1347 15.7471 12.9688C15.5898 12.9033 15.4185 12.842 15.2348 12.7859C14.7162 12.043 13.8858 11.4009 12.844 10.9209C14.1673 10.9562 15.388 11.1942 16.324 11.5842C16.8306 11.7953 17.2896 12.0658 17.6334 12.4044C17.9798 12.7457 18.25 13.2007 18.25 13.7497C18.25 14.2986 17.9798 14.7536 17.6334 15.0949C17.2896 15.4335 16.8306 15.704 16.324 15.9151C16.0189 16.0422 15.6835 16.1532 15.3242 16.2463Z"/>
    </svg>
  </Icon>
);

export default Users;
