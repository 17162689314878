import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Box, Container, styled, Typography } from '@mui/material';
import DesktopPageTabs from './desktop=page-tabs';
import { PageTab } from './desktop=page-tabs/desktop-page-tabs';
import useIsMobile from '../../../hooks/use-is-mobile.hook';
import MobilePageTabs from './mobile-page-tabs';
import { Tabs } from '../../../modules/provider/components/pages/profile/profile';
import { colors } from '../../../config/theme/colors';
import useQuery from '../../../hooks/use-query.hook';
import Stepper from "../../../modules/public/components/book-appointment/stepper";
import ClientStepper  from "../../../modules/client/components/pages/create-appointment/stepper";
import StepsEnum from "../../../modules/public/components/book-appointment/enums/steps.enum";
import ClientStepsEnum from "../../../modules/client/components/pages/create-appointment/enums/steps.enum";
import {CreateAppointmentDto} from "../../../services/api/appointment/dto/create-appointment.dto";
import {defaultCreateAppointmentValue} from "../../../services/api/appointment/default-appointment-value";

const StyledContainer = styled(Container)(
  ({ theme }) => `
    padding: 0 16px;
    margin: 0;
    
    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 0 48px 0 38px !important;
    }
`,
);

const Header = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 8px 0 !important;
  }
`,
);

const stepDescriptions: { [key: number]: string } = {
  [StepsEnum.CATEGORY]: 'Step 1: Select a therapy',
  [StepsEnum.SERVICE]: 'Step 2: Service',
  [StepsEnum.DATE_TIME]: 'Step 3: Date and Time Period',
  [StepsEnum.TYPE]: 'Step 4: Appointment type',
  [StepsEnum.LOCATION]: 'Step 5: Location',
  [StepsEnum.CREATE_PROFILE]: 'Step 6: Login/Sign Up',
  [StepsEnum.PAYMENT]: 'Step 7: Payment',
};

interface Props {
  title?: string;
  tabs?: PageTab[];
  activeTab?: Tabs;
  onTabChange?: (id: number) => void;
  children: ReactNode;
  isStepper?: boolean;
}

const PageContainer = ({ title, tabs, activeTab, onTabChange, children, isStepper = false }: Props): ReactElement | null => {
  const [loading, setLoading] = useState<boolean>(!!tabs);
  const isMobile = useIsMobile();
  const PageTabs = isMobile ? MobilePageTabs : DesktopPageTabs;
  const { params, setParams } = useQuery();
  const [activeStep, setActiveStep] = useState<StepsEnum>(StepsEnum.PAYMENT);
  const [appointment, setAppointment] = useState<CreateAppointmentDto>(defaultCreateAppointmentValue);
  const [activeClientStep, setActiveClientStep] = useState<ClientStepsEnum>(ClientStepsEnum.PAYMENT);

  const handleChangeStep = (step: StepsEnum) => {
    const address = appointment.address ? appointment.address.trim() : false;

    if (step === StepsEnum.CATEGORY && activeStep === StepsEnum.PAYMENT) {
      return;
    }
    if (step === StepsEnum.SERVICE && !appointment.category_id) {
      return;
    }
    if (step === StepsEnum.DATE_TIME && !appointment.service_id) {
      return;
    }
    if (step === StepsEnum.TYPE && !appointment.date) {
      return;
    }
    if (step === StepsEnum.LOCATION && !appointment.type) {
      return;
    }
    if (step === StepsEnum.CREATE_PROFILE && !address) {
      return;
    }

    setActiveStep(step);
  };



  const handleTabChange = (tab: number) => {
    setParams({ tab });
    onTabChange && onTabChange(tab);
  };

  useEffect(() => {
    if (tabs && !params.tab) {
      setParams({ tab: 0 });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (params.tab && onTabChange) {
      onTabChange(parseInt(params.tab));
      setLoading(false);
    }
  }, [params.tab]);

  if (loading) {
    return null;
  }

  return (
      <StyledContainer maxWidth="xl">
        {((isStepper) && !isMobile && !params.isClient) &&
            <Stepper
              stepDescription={isMobile ? stepDescriptions[activeStep] : undefined}
              activeStep={activeStep}
              onStepChange={handleChangeStep}
              isWideStepper={true}
        />}
        {((isStepper) && !isMobile && params.isClient) &&
            <ClientStepper
                activeStep={activeClientStep}
                stepDescription={isMobile ? stepDescriptions[activeStep] : undefined}
                onStepChange={handleChangeStep}
            />}
        <Header>
          {title && (
              <Typography sx={{mt: 4, mb: 1, ml: 2}} variant="h5">
                {title}
              </Typography>
          )}
        </Header>
        {tabs && onTabChange && (
            <PageTabs tabs={tabs} activeTab={activeTab || Tabs.PERSONAL_DETAILS} onChange={handleTabChange}/>
        )}
        {children}
      </StyledContainer>
  );
};

export default PageContainer;
