export const GET_PROVIDERS_REQUEST = 'GET_PROVIDERS_REQUEST';
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const GET_PROVIDERS_FAILURE = 'GET_PROVIDERS_FAILURE';

export const UPDATE_PROVIDER_REQUEST = 'UPDATE_PROVIDER_REQUEST';
export const UPDATE_PROVIDER_SUCCESS = 'UPDATE_PROVIDER_SUCCESS';
export const UPDATE_PROVIDER_FAILURE = 'UPDATE_PROVIDER_FAILURE';

export const DELETE_PROVIDER_REQUEST = 'DELETE_PROVIDER_REQUEST';
export const DELETE_PROVIDER_SUCCESS = 'DELETE_PROVIDER_SUCCESS';
export const DELETE_PROVIDER_FAILURE = 'DELETE_PROVIDER_FAILURE';
