import { ReactElement, useEffect, useState } from "react";
import { MobileSidebar } from '../sidebar';
import Button from '../../ui/button';
import { ClientRouteEnum } from '../../../modules/client/routes/enums/route.enum';
import SidebarContent from "./sidebar-content";
import {useDispatch, useSelector} from "react-redux";
import {selectAuth} from "../../../store/selectors";
import {logout} from "../../../store/actions/auth";
import {useConfirmation} from "../../../hooks";

const MobileHeader = (): ReactElement => {
    const [activeLink, setActiveLink] = useState<string>('');
    const { authUser } = useSelector(selectAuth);
    const dispatch = useDispatch();
    const { Confirmation, showConfirmation } = useConfirmation();

    const handleLogout = () => {
        showConfirmation({
            text: 'Confirm logout',
            description: 'Are you sure you want to log out?',
            onConfirm: () => {
                dispatch(logout());
            },
        });
    };

  return (
      <>
        {Confirmation}
        <MobileSidebar
          sidebarContent={<SidebarContent activeLink={activeLink} setActiveLink={setActiveLink} />}
          headerAction={
              authUser ?
                  <Button color="inherit" onClick={handleLogout}>
                      Logout
                  </Button> :
                  <Button color="inherit" onClick={() => (window.location.href = ClientRouteEnum.SIGN_UP)}>
                      Sign Up
                  </Button>
              }
        />
      </>
  );
};

export default MobileHeader;
