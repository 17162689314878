export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAILURE = 'VERIFY_FAILURE';

export const SEND_VERIFICATION_CODE_REQUEST = 'SEND_VERIFICATION_CODE_REQUEST';
export const SEND_VERIFICATION_CODE_SUCCESS = 'SEND_VERIFICATION_CODE_SUCCESS';
export const SEND_VERIFICATION_CODE_FAILURE = 'SEND_VERIFICATION_CODE_FAILURE';

export const APPLY_VERIFICATION_CODE_REQUEST = 'APPLY_VERIFICATION_CODE_REQUEST';
export const APPLY_VERIFICATION_CODE_SUCCESS = 'APPLY_VERIFICATION_CODE_SUCCESS';
export const APPLY_VERIFICATION_CODE_FAILURE = 'APPLY_VERIFICATION_CODE_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const GET_AUTH_USER_REQUEST = 'GET_AUTH_USER_REQUEST';
export const GET_AUTH_USER_SUCCESS = 'GET_AUTH_USER_SUCCESS';
export const GET_AUTH_USER_FAILURE = 'GET_AUTH_USER_FAILURE';
