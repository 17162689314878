export enum ProviderAdditionalsEnum {
  PROFESSIONAL_QUALIFICATIONS = 1,
  PROFESSIONAL_REFERENCES,
  WORK_WITH_CHILDREN_CLEARANCE,
  NATIONAL_POLICE_CLEARANCE,
  PROFESSIONAL_INDEMNITY_AND_DECLARATION,
  WORK_WITH_CHILDREN,
  OVERSEAS_RESIDENCY,
  OTHER_PROFESSIONAL_REGISTRATIONS
}
